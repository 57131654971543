import React from 'react'

// Components
import { kebabCase } from 'lodash'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { PostsExcert } from '../components/post-excerpt'
import { PageProps, Post } from '../types'

type DataProps = PageProps<
  {
    allMarkdownRemark: {
      edges: { node: Post }[]
      totalCount: number
    }
  },
  {
    tag: string
  }
>

const Tags: React.FC<DataProps> = ({ location, pageContext, data }) => {
  const { tag } = pageContext
  const { edges } = data.allMarkdownRemark

  const tagHeader = `Tag : ${tag}`

  return (
    <Layout
      pages={data.pages}
      location={location}
      title={tagHeader}
      site={data.site}
      nav={[
        { href: '/tags', title: 'Tags' },
        {
          href: `/tags/${kebabCase(tag)}`,
          title: tag
        }
      ]}>
      <SEO title={tagHeader} />
      <PostsExcert
        site={data.site}
        posts={edges.map(({ node: post }) => post)}
      />
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          summary
        }
      }
    }
    pages: allFile(
      filter: {
        sourceInstanceName: { eq: "pages" }
        childMarkdownRemark: { id: { glob: "*" } }
      }
    ) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 350)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            categories
            tags
          }
        }
      }
    }
  }
`
